import Head from 'next/head';
import {useEffect, useState} from 'react';
import {useRouter} from 'next/router';

import {InteractionType} from '@azure/msal-browser';
import {MsalAuthenticationTemplate} from '@azure/msal-react';

import ErrorComponent from '../components/ErrorComponent';
import Layout from '../components/Layout';
import Loading from '../components/Loading';

import {loginRequest} from '../lib/authConfig';
import {useLocaleContextActions} from 'tovera-shared/context/LocaleContext';

export default function Home() {
    const {translate} = useLocaleContextActions();
    const router = useRouter();
    const authRequest = {...loginRequest};

    // Always go to assets for now
    useEffect(() => {
        router.push('/assets');
    }, []);

    return (
        <>
            <Head>
                <title>{translate('index_page.title')}</title>
            </Head>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
                errorComponent={ErrorComponent}
                loadingComponent={Loading}
            >
                <Layout subHeaderTitle={translate('index_page.title')}></Layout>
            </MsalAuthenticationTemplate>
        </>
    );
}
